import { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux"
import SpartanApi from "../helpers/api";
import {Button, Container, Row, Col, Card} from 'react-bootstrap'
import VehicleCard from "../shared/vehicle-card";
import * as ACTIONS from '../constants/actions';
import {useNavigate} from 'react-router-dom';
import car from '../assets/car.png'
import SpartanButton from '../shared/form-components/spartan-button';
import AddVehicleModal from "../modals/add-vehicle-modal";

export default function Vehicles () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const vehicles = useSelector(s => s.vehicles.vehicles);
    const user     = useSelector(s => s.user.data);
    const selectedOrganization = useSelector(s => s.organization.selectedOrganization);
    const [modalOpen, setModalOpen] = useState(() => false);

    const loadVehicles = async () => {
        const hasVehicles = await api.getCustomerOrganizationVehicles({organization_id: selectedOrganization.id})
        if ((!hasVehicles || !hasVehicles.length)) {
            
            return;
        }
    }

    const closeModal = async() => {
        loadVehicles();
        setModalOpen(() => false)
    }

    useEffect(() => {
        if (!selectedOrganization) return;
        loadVehicles();
    }, [selectedOrganization]);

    const buildVehicles = () => {

        if (!vehicles.length){
            return (
                <div className="empty-vehicle-container">
                    <span className="spartan-heading">Hi there,</span><br/>
                    <span className="spartan-heading-bold">{user.first_name}</span>
                    <div className="car-container text-center">
                        <img src={car} />
                    </div>
                    <div className='description-box text-center'>
                        <h5 className='spartan-primary-text'>Add {selectedOrganization.organization_name}'s first vehicle</h5>
                        <p className='spartan-secondary-text'>Add your first vehicle to get started! Tap on “Add vehicle” below to start</p>
                    </div>
                    <div>
                        <SpartanButton onClick={() => setModalOpen(true)}> 
                            Add vehicle
                        </SpartanButton>
                    </div>
                </div>
            )
        }

        return (
            <Row>
                {vehicles.map((v, i )=> {
                    return (
                        <Col xs="12" sm="6" md="6" lg="4" className="mb-5">
                            <VehicleCard key={i} data={v} />
                        </Col>
                    )
                })}
            </Row>
        )
    }

    let modal = null;

    if (modalOpen){
        modal = <AddVehicleModal close={closeModal}/>;
    }

    if (!selectedOrganization) return null;
    
    return (
        <div className="main-container">
            {modal}
            <Container>
                <div className="empty-vehicle-container">
                    <span className="spartan-heading">Hi there,</span><br/>
                    <span className="spartan-heading-bold">{user.first_name}</span>
                    <div>
                        <SpartanButton onClick={() => setModalOpen(true)}> 
                            Add vehicle
                        </SpartanButton>
                    </div>
                </div>
                <Row>
                    {buildVehicles()}
                </Row>
            </Container>
        </div>
    )
}