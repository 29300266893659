import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from '../helpers/api';
import {Button, Card, Form} from 'react-bootstrap';
import AddDeviceModal from '../modals/add-device-modal';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';

export default function VehicleCard({data}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [modalOpen, setModalOpen] = useState(() => false);
    const [vehicle, setVehicle] = useState(() => {
        return {}
    })


    useEffect(() => {
        if (!data) return;
        setVehicle(data);
        console.log(data)
    }, [data]);

    

    const buildDeviceSection = () => {
        if (!vehicle || !vehicle.id) return null;
        if (!vehicle || !vehicle.vehicle_devices || !vehicle.vehicle_devices.length){
            return (
                <div className="mt-5">
                    <a onClick={() => setModalOpen(true)}>Add Device To Vehicle</a>
                </div>
            )
        }
       
        return (
            <div className="mt-5">
                <h5>Devices On Vehicle</h5>
                <ul>
                    {vehicle.vehicle_devices.map((v, i) => {
                        return( 
                        
                        <li key={i}>
                            {v.serial_number} - {v.device_type_data.model} | {v.device_type_data.device_line} | {v.device_type_data.device_name} ({v.placement}) - {v.device_warranty ? `Covered Until ${moment(v.device_warranty.warranty_end_date).format('MM/DD/YYYY')}` : ''} 
                            <br/>
                            <a href={v.device_type_data.device_manual_url} target="_blank"> Download the Manual</a>
                        
                        </li>
                        )
                    })}
                </ul>
                <a className="mt-4" onClick={() => setModalOpen(true)}>Add Device To Vehicle</a>
            </div>
        )

    }

    const closeModal = () => {
        setModalOpen(false)
    }

    let modal = null;

    if (modalOpen) {
        modal = <AddDeviceModal close={closeModal} customer_vehicle_id={vehicle.id}/>
    }

    
        return (
            <Card className="mt-5" bg="dark" text="white">
                {modal}
                
                <Card.Body className='spartan-panel'>
                        <h5>{vehicle.vehicle_make} {vehicle.vehicle_model}</h5>
                        <h6>{vehicle.vehicle_year} | {vehicle.vehicle_color}</h6>
                        <h6>VIN - {vehicle.vin_number}</h6>

                    {buildDeviceSection()}
                </Card.Body>
            </Card>
        )
    
}