import axios from 'axios';
import * as KEYS from '../constants/keys';
import * as ACTIONS from '../constants/actions';

axios.defaults.baseURL = "https://internal-spartan-tracking-server.westus.cloudapp.azure.com"

export default class SpartanApi {
    constructor({dispatch, navigate}){
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.getToken();
        this.getUser();
    }

    setToken = (token) => {
        axios.defaults.headers.common.Authorization = token;
        window.localStorage.setItem(KEYS.TOKEN_KEY, token);
    }

    getToken = () => {
        let token = window.localStorage.getItem(KEYS.TOKEN_KEY);
        if (!token) return null;
        axios.defaults.headers.common.Authorization = token;
    }

    setUser = (user) => {
        this.dispatch({type: ACTIONS.SET_USER, payload: user});
        window.localStorage.setItem(KEYS.USER_DATA_KEY, JSON.stringify(user));
    }

    getUser = () => {
        try {
            let storedUser = window.localStorage.getItem(KEYS.USER_DATA_KEY);
            if (!storedUser) return;
            storedUser = JSON.parse(storedUser);
            this.dispatch({type: ACTIONS.SET_USER, payload: storedUser});
            return storedUser;
        } catch(err) {
            console.log(err);
        }
    }

    checkLoggedIn = () => {
        try {
            let user = window.localStorage.getItem(KEYS.USER_DATA_KEY);
            return user;
        } catch(err) {
            
            console.log(err)
            return null
        }
    }

    createUser = async (user) => {
        try {
            const {data} = await axios.post('/customer/create-customer', user);
            this.setToken(data.token);
            this.setUser(data.user);
            return true;
        } catch(err) {
            console.log("Error Creating User: ", err);
            return null;
        }
    }

    login = async (user) => {
        try {
            const {data} = await axios.post('/customer/login', user);
            this.setToken(data.token);
            this.setUser(data.user);
            return true;
        } catch(err) {
            console.log("Error Logging User In; ", err)
            return null;
        }
    }

    updateUser = async (user) => {
        try {
            await axios.post('/customer/update-customer', user);
            return true;
        } catch(err) {
            console.log("Error Updating the User: ", err);
            return null;
        }
    }

    createUpdateCustomerAddress = async (address) => {
        try {
            await axios.post('/customer/create-update-customer-address', address);
            return true;
        } catch(err) {
            console.log("Error Creating/Updating Customer Address: ", err);
            return null;
        }
    }

    createCustomerVehicle = async (vehicle) => {
        try {
            await axios.post('/customer/create-customer-vehicle', vehicle);
            return true;
        } catch(err) {
            console.log("Error Creating the Customer Vehicle: ", err);
            return null;
        }
    }

    updateCustomerVehicle = async (vehicle) => {
        try {
            await axios.post('/customer/update-customer-vehicle', vehicle);
            return true;
        } catch(err) {
            console.log("Error Updating the Customer Vehicle: ", err);
            return null;
        }
    }

    getCustomerVehicles = async () => {
        try {
            const {data} = await axios.get('/customer/get-customer-vehicles');
            this.dispatch({type: ACTIONS.SET_USER_VEHICLES, payload: data.vehicles || []});
            return data.vehicles;
        } catch(err) {
            console.log("Error Getting Customer Vehicles", err);
            return null;
        }
    }

    createCustomerVehicleDevice = async (device) => {
        try {
            await axios.post('/customer/create-customer-vehicle-device', device);
            return true;
        } catch(err) {
            console.log("Error Creating Customer Vehicle Device: ", err);
            return null;
        }
    }

    createUpdateCustomerDeviceWarranty = async (warranty) => {
        try {
            await axios.post('/customer/create-update-customer-device-warranty', warranty);
            return true;
        } catch(err) {
            console.log("Error Creating/Updating the Device Warranty: ", err);
            return null;
        }
    }

    createCustomerOrganization = async (requestBody) => {
        try {
            await axios.post('/customer/create-customer-organization', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    getCustomerOrganizations = async () => {
        try {
            const {data} = await axios.get('/customer/get-customer-organizations')
            this.dispatch({type: ACTIONS.SET_ORGANIZATION_OPTIONS, payload: data.organizations});
            return true;
        } catch(err){
            console.log("ERROR: ", err);
            return null;
        }
    }

    getCustomerOrganizationVehicles = async (requestBody) => {
        try {
            const {data} = await axios.get('/customer/get-customer-organization-vehicles', {params:requestBody})
            this.dispatch({type: ACTIONS.SET_USER_VEHICLES, payload: data.vehicles || []});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    createCustomerOrganizationVehicle = async (requestBody) => {
        try {
            await axios.post('/customer/create-customer-organization-vehicle', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null
        }
    }

    updateCustomerOrganizationVehicle = async (requestBody) => {
        try {
            await axios.post('/customer/update-customer-organization-vehicle', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    resetPassword = async (requestBody) => {
        try {
            await axios.post('/customer/reset-password', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

}