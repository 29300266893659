import SpartanApi from "../helpers/api";
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {Container, Card, Form, Button} from 'react-bootstrap'
import {useLocation} from 'react-router-dom';

export default function ResetPassword() {
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const location = useLocation();
    const [token, setToken] = useState(() => undefined);
    const [newPassword, setNewPassword] = useState(() => {
        return {
            user_password: '',
            confirm_user_password: ''
        }
    });

    const [confirmationMessage, setConfirmationMessage] = useState(() => null)

    useEffect(() => {
        if (!location) return;
        const searchParams = new URLSearchParams(location.search);
        const t = searchParams.get('token')
        setToken(() => t);
    }, [location]);

    const attemptReset = async (evt) => {
        evt.preventDefault();
        if (newPassword.user_password != newPassword.confirm_user_password) return;
        const success = await api.resetPassword({token, user_password: newPassword.user_password});
        if (!success){
            setConfirmationMessage("Unable to reset your password at this time, please check your request and try again later.")
            return;
        }
        setConfirmationMessage("Your password has been successfully reset, you will be taken to the Login Page shortly.");
        setTimeout(() => {
            window.location.href = "/"
        }, 5000)
       
    }

    const handleChange = (key, value) => {
        setNewPassword({...newPassword, [key]: value})
    }

    const buildConfirmationMessage = () => {
        if (!confirmationMessage) return null;
        return (
            <div className="text-center">
                {confirmationMessage}
            </div>
        )
    }

    return (
        <div className="main-container">
            <Container>
            <Card bg="dark" text="white">
                <Card.Header>
                    Reset your Password
                </Card.Header>
                <Card.Body>
                    {buildConfirmationMessage()}
                    <Form onSubmit={attemptReset}>
                        <Form.Group>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control minLength={8} type="password" value={newPassword.user_password} onChange={evt => handleChange('user_password', evt.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control minLength={8} type="password" value={newPassword.confirm_user_password} onChange={evt => handleChange('confirm_user_password', evt.target.value)} required/>
                        </Form.Group>
                        <div className="mt-4 text-center">
                            <Button type="submit" disabled={newPassword.confirm_user_password != newPassword.user_password}>Reset Password</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            </Container>
        </div>
    )
}