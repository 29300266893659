import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers';
import Navbar from './shared/navbar';
import EditAddress from './components/edit-address';
import Vehicles from './components/vehicles';
import Organization from './components/organization';
import ResetPassword from './components/reset-password';
const store = createStore(reducers);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
   
   
    <Router>
    <Navbar/>
      <Routes>
        
        <Route path="/organization" element={<Organization/>} />
        <Route path="/edit-address" element={ <EditAddress/>} /> 
        <Route path="/add-vehicle" element={<Vehicles/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        <Route  path="/" element={ <App />} />
      </Routes>
    </Router>

  </Provider>
);
