import * as ACTIONS from '../constants/actions';
import {combineReducers} from 'redux';

export default combineReducers({
    vehicles: (state = [], action) => {
        switch(action.type) {
            case ACTIONS.SET_USER_VEHICLES: {
                return action.payload;
            }
            default: return state;
        }
    }
})