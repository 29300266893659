import { Modal, Form, Button } from "react-bootstrap";
import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import SpartanApi from "../helpers/api";
import { useNavigate } from "react-router-dom";
import './modals.scss';

export default function LoginModal ({close}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [user, setUser] = useState(() => {
        return {
            email: '',
            user_password: ''
        }
    });

    const attemptLogin = async (evt) => {
        evt.preventDefault();
        const loggedIn = await api.login(user);

        if (!loggedIn){
            close();
            return;
        }

        navigate("/add-vehicle")

    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
                Login To Your Account
            </Modal.Header>

            <Modal.Body className="spartan-modal">
                <Form onSubmit={attemptLogin}>
                    <Form.Group>
                        <Form.Label>
                            Your Email: 
                        </Form.Label>
                        <Form.Control type="email" value={user.email} onChange={evt => setUser({...user, email: evt.target.value})} required/>
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Label>
                            Your Password: 
                        </Form.Label>
                        <Form.Control type="password" value={user.user_password} onChange={evt => setUser({...user, user_password: evt.target.value})} required/>
                    </Form.Group>
                    <div className="mt-4 text-center">
                        <Button type="submit">LOGIN</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}