import SpartanTextInput from "../shared/form-components/spartan-text-input";
import SpartanButton from "../shared/form-components/spartan-button";
import SpartanApi from "../helpers/api";
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Button, Card, Container, Form } from "react-bootstrap";
import * as ACTIONS from '../constants/actions';
import CreateOrganizationModal from "../modals/create-organization-modal";
import { useNavigate } from "react-router-dom";

export default function Organization(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const appState = useSelector(s => s);
    const [organizationId, setOrganizationId] = useState(() => undefined);
    const [modalOpen, setModalOpen] = useState(() => false);

    
    const loadInitialData = async () => {
        await api.getCustomerOrganizations();
    }

    const closeModal = async () => {
        await loadInitialData();
        setModalOpen(false)
    }
    
    const selectOrganization = (evt) => {
        evt.preventDefault();
        let organization = appState.organization.organizations.find((o) => o.id == organizationId );
        console.log(organization);
        dispatch({type: ACTIONS.SELECT_ORGANIZATION, payload: organization})
        navigate('/add-vehicle')
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    const buildOrganizationOptions = () => {
        return (
            <div className="mt-5">
                <Card bg="dark" text="white">
                    <Card.Header>
                        <h4 className="text-center">Select An Organization</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={selectOrganization}>
                            <Form.Group>
                                <Form.Select 
                                 value={organizationId}
                                 onChange={evt => setOrganizationId(evt.target.value)}
                                 required>
                                    <option></option>
                                    {appState.organization.organizations.map((o, i) => {
                                        return (
                                            <option key={i} value={o.id}>{o.organization_name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <div className="text-center mt-5">
                                    <Button type="submit">Choose Organization</Button>
                            </div>
                        </Form>
                        <div className="mt-4 text-center">
                            Need to create a new organization? <a onClick={() => setModalOpen(true)}> Click Here.</a>
                        </div>
                    </Card.Body>
                </Card>
               
            </div>
        )
    }

    const buildEmptyState = () => {
        return (
            <Card bg="dark" text="white">
                <Card.Header>
                    <h4 className="text-center">
                        No Organizations Found
                    </h4>
                </Card.Header>
                <Card.Body className="text-center">
                    <p>No Organizations are linked to your account. Either you'll need to contact your company manager OR <a onClick={() => setModalOpen(true)}>click here</a> to create one.</p>
                </Card.Body>
            </Card>
        )
    }

    let section = null;

    if (!appState.organization.organizations.length) {
        section = buildEmptyState()
    } else {
        section = buildOrganizationOptions();
    }

    let modal = null;

    if (modalOpen){
        modal = <CreateOrganizationModal close={closeModal}/>
    }

    
    return (
        <div className="main-container">
            {modal}
            <Container>
                {section}
            </Container>
        </div>
    )
}