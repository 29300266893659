import {Modal, Form, Button} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../helpers/api';
import SpartanButton from '../shared/form-components/spartan-button';
import SpartanTextInput from '../shared/form-components/spartan-text-input';

export default function AddVehicleModal({close, data}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [vehicle, setVehicle] = useState(() => {
        return {
            vin_number: '',
            vehicle_make: '',
            vehicle_model: ''
        }
    });

    
    const selectedOrganization = useSelector(s => s.organization.selectedOrganization);

    const attemptSaveVehicle = async (evt) => {
        evt.preventDefault();
        if (!vehicle.id){
            await api.createCustomerOrganizationVehicle({...vehicle, organization_id: selectedOrganization.id})
        } else {
            await api.updateCustomerOrganizationVehicle({...vehicle, organization_id: selectedOrganization.id})
        }

        close();
    }


    useEffect(() => {
        if (!data) return;
        setVehicle(data);
        console.log(data)
    }, [data]);

    return (
        <Modal show={true} onHide={close} style={{backgroundColor: 'rgba(0,0,0,0.9)'}}>
            <Modal.Header className="spartan-modal" closeButton>
                <h6 className="text-center">
                {vehicle.id ? 'UPDATE': 'ADD'} VEHICLE
                </h6>
            </Modal.Header>
            <Modal.Body className="spartan-modal">
            <Form onSubmit={attemptSaveVehicle}>
                        <Form.Group>
                            <SpartanTextInput label={'Vehicle Make'} type="text" value={vehicle.vehicle_make} onChange={evt => {setVehicle({...vehicle, vehicle_make: evt.target.value})}} required/>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <SpartanTextInput label={'Vehicle Model'} type="text" value={vehicle.vehicle_model} onChange={evt => {setVehicle({...vehicle, vehicle_model: evt.target.value})}} required/>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <SpartanTextInput label={'Vehicle Color'} type="text" value={vehicle.vehicle_color} onChange={evt => {setVehicle({...vehicle, vehicle_color: evt.target.value})}} required/>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <SpartanTextInput label={'Vehicle Year'} type="number" value={vehicle.vehicle_year} onChange={evt => {setVehicle({...vehicle, vehicle_year: evt.target.valueAsNumber})}} required/>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <SpartanTextInput label={'Last 7 of VIN'} type="text" minLength={7} maxLength={7} value={vehicle.vin_number} onChange={evt => { setVehicle({...vehicle, vin_number: evt.target.value})}} required/>
                        </Form.Group>

                        <div className="mt-4 text-center">
                            <SpartanButton type="submit">{vehicle.id ? 'SAVE': 'CREATE'} VEHICLE</SpartanButton>
                        </div>
                    </Form>
            </Modal.Body>
        </Modal>
    )

    
}