import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/api';
import { Card, Form, Container, Button } from 'react-bootstrap';
import { Country, State, City} from 'country-state-city';

export default function EditAddress() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [address, setAddress] = useState(() => {
        return {
            address_line_one: '',
            address_line_two: '',
            city: '',
            state: '',
            country: 'US',
            zipcode: ''
        }
    })

    const [countries, setCountries] = useState(() => {
        return Country.getAllCountries().map(c => {
            return {
                code: c.isoCode,
                name: c.name
            }
        })
    });

    const [states, setStates] = useState(() => {
        return State.getStatesOfCountry("US").map(s => {
            return {
                code: s.isoCode,
                name: s.name
            }
        })
    })

    const attemptEditAddress = async (evt) => {
        evt.preventDefault();
        const addressSaved = await api.createUpdateCustomerAddress(address);

        if (!addressSaved){
            //TODO: FIRE AN ALERT
            console.log("FIRE ALERT")
            return;
        }

        navigate('/add-vehicle')
    }

    const onChangeCountry = (countryCode) => {
        console.log(countryCode)
        setAddress({...address, country: countryCode});
        setStates(State.getStatesOfCountry(countryCode).map(s => {
            return {
                code: s.isoCode,
                name: s.name
            }
        }))
    }

    return (
        <div className="main-container">
            <Container>
                <Card bg="dark" text="white">
                    <Card.Header>
                        Edit Your Address
                    </Card.Header>

                    <Card.Body>
                        <Form onSubmit={attemptEditAddress}>
                            <Form.Group>
                                <Form.Label>
                                    Address Line One
                                </Form.Label>

                                <Form.Control value={address.address_line_one} onChange={evt => {setAddress({...address, address_line_one: evt.target.value})}} required/>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    Address Line Two
                                </Form.Label>

                                <Form.Control value={address.address_line_two} onChange={evt => {setAddress({...address, address_line_two: evt.target.value})}}/>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    Country
                                </Form.Label>
                                <Form.Select value={address.country} onChange={evt => onChangeCountry(evt.target.value)} required>
                                    {countries.map((c, i) => {
                                        return (
                                            <option key={i} value={c.code}>{c.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    State
                                </Form.Label>
                                <Form.Select value={address.state} onChange={evt => setAddress({...address, state: evt.target.value})} required>
                                    {states.map((c, i) => {
                                        return (
                                            <option key={i} value={c.code}>{c.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                            
                            <Form.Group className="mt-4">
                                <Form.Label>
                                    City
                                </Form.Label>

                                <Form.Control type="text" value={address.city} onChange={evt => setAddress({...address, city: evt.target.value})} required/>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    Zipcode
                                </Form.Label>

                                <Form.Control type="text" value={address.zipcode} onChange={evt => setAddress({...address, zipcode: evt.target.value})} required/>
                            </Form.Group>

                            <div className="mt-4 text-center">
                                    <Button type="submit">UPDATE ADDRESS</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}