import './form-parts.scss';

export default function SpartanTextInput ({type="text", value, onChange, label, required = false}){
    return (
        <div className="spartan-text-input-container">
            <div className="label">
                {label}
            </div>
            <input type={type} value={value} onChange={onChange}  required={required}/>
        </div>
    )
}