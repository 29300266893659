import {Modal, Form, Card, Button} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import SpartanApi from '../helpers/api';

export default function CreateOrganizationModal({close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [organization, setOrganization] = useState(() => {
        return {
            organization_name: ''
        }
    })

    const createOrganization = async (evt) => {
        evt.preventDefault();
        await api.createCustomerOrganization(organization);
        close();
    }

    return (
        <Modal show={true} onHide={close} >
            <Modal.Header className="spartan-modal" closeButton>
                ADD YOUR ORGANIZATION
            </Modal.Header>

            <Modal.Body className="spartan-modal">
                <Form onSubmit={createOrganization}>
                    <Form.Group>
                        <Form.Label>
                            Organization Name
                        </Form.Label>
                        <Form.Control type="text"  
                                      value={organization.organization_name}
                                      onChange={evt => setOrganization({...organization, organization_name: evt.target.value})} 
                                      required/>
                    </Form.Group>
                    <div className="mt-5 text-center">
                        <Button type="submit">Create Organization</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}