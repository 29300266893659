import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import SpartanApi from '../helpers/api';
import { useNavigate, useLocation } from 'react-router-dom';
import './navbar.scss';
import * as ACTIONS from '../constants/actions';

export default function Navbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const appState = useSelector(s => s);
    const organization = useSelector(s => s.organization)

    

    const loadInitialData = async() => {
        await api.getCustomerOrganizations();
    }

    const checkData = async () => {

        
        const user = await api.checkLoggedIn();

        
        if (user && !organization.selectedOrganization){
            navigate('/organization')
        }

        loadInitialData();
    }
    
    useEffect(() => {
        if (location.pathname == '/reset-password') return;
        checkData();
    }, []);

   

    
    const handleOrganizationChange = (evt) => {
        const org = organization.organizations.find(o => o.id == evt.target.value);
        dispatch({type: ACTIONS.SELECT_ORGANIZATION, payload: org});
    }

    const buildOrganizationSelect = () => {
        if (!appState.organization.organizations.length) return null;

        return (
            <div className="organization-container">
                <select value={organization.selectedOrganization ? organization.selectedOrganization.id : undefined}
                        onChange={handleOrganizationChange}>
                    {appState.organization.organizations.map((o, i) => {
                        return <option key={i} value={o.id}>{o.organization_name}</option>
                    })}
                </select>
            </div>
        )
    }
    return (
        <div className="spartan-navbar">
            <div className="logo-container">
                <img className="img-fluid" src="https://spartanradarco.wpenginepowered.com/wp-content/uploads/2021/06/SpartanRadar_Logo_Light.svg"/>
            </div>
            {buildOrganizationSelect()}
        </div>
    )
}